import React from 'react';
import {
    useQuery
} from 'react-query';
import {useKeycloak} from "@react-keycloak/web";
import PropTypes from "prop-types";
import {useSnackbar} from "notistack";
import LoadingScreen from "../components/LoadingScreen";
import useAxios from "../hooks/useAxios";

const BackEndConfigContext = React.createContext({
    authUser: {},
    config: {},
    allGroups: [],
    provinces: [],
    isBranchManager: false,
    isReceptionist: false,
    isBranchManagerOrReceptionist: false,
    belongedBranchId: ''

});
BackEndConfigContext.displayName = 'BackEndConfigContext';

BackEndConfigProvider.propTypes = {
    children: PropTypes.node,
};


function BackEndConfigProvider({ children }) {
    const { keycloak } = useKeycloak();
    const { enqueueSnackbar } = useSnackbar();
    const { axios } = useAxios();

    const fetchConfig = async () => {
        if (!keycloak.authenticated) {
            return {'error': '401'};
        }

        const { data: response } = await axios.get(`/config`);
        return response.data;
    }

    const {
        data,
        isLoading,
        isIdle,
        isSuccess,
    } = useQuery(
        ['config'],
        fetchConfig,
        {
            staleTime: 1000 * 60 * 60,
            onError: (res) => {
                enqueueSnackbar(res.response?.data?.message || res.message, { variant: 'error'});
            }
        }
    );

    if (isSuccess) {
        const copyData = {...data};
        const config = {...copyData.config};
        const provinces = copyData.provinces ? [...copyData.provinces] : [];
        const allGroups = copyData?.allGroups ? [...copyData.allGroups] : [];
        delete copyData.config;
        delete copyData.allGroups;
        const authUser = copyData;
        return (
            <BackEndConfigContext.Provider value={{config, authUser, allGroups, provinces}}>{children}</BackEndConfigContext.Provider>
        );
    }

    if (isLoading || isIdle) {
        return <LoadingScreen />;
    }

    return <>{children}</>;
}

export { BackEndConfigProvider, BackEndConfigContext };
