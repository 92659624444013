import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
import ReceiptLogo from '../assets/images/LogoReceipt.jpg';
import demoLogo from '../assets/images/demo_bg.jpg';
import settings from "../settings";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box sx={{...sx }}>
      <img
          src={settings.stage === "demo" ? demoLogo : ReceiptLogo}
          alt='start up logo'
          loading="lazy"
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
