import PropTypes from 'prop-types';
// icons
import { Icon } from '@iconify/react';
// @mui
import {Box} from '@mui/material';
import {forwardRef} from 'react';

// ----------------------------------------------------------------------

const Iconify = forwardRef(({ icon, sx, ...other }, ref) => <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} ref={ref} />);

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
};

export default Iconify;
