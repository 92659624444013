import React from 'react';
import {useKeycloak} from "@react-keycloak/web";
import PropTypes from "prop-types";
import { IdleTimerProvider } from 'react-idle-timer'


IdleProvider.propTypes = {
    children: PropTypes.node,
};


export default function IdleProvider({ children }) {
    const { keycloak } = useKeycloak();
    const onIdle = () => {
        if (keycloak.authenticated) {
            keycloak.logout({ redirectUri: window.location.origin });
        }
    }

    return (
        <IdleTimerProvider
            timeout={1000 * 60 * 15}
            onIdle={onIdle}
        >
            {children}
        </IdleTimerProvider>
    );
}
