import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
// @mui
import {Dialog, Slide} from '@mui/material';
import {forwardRef} from "react";
// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props}/>);

DialogAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.object,
  variants: PropTypes.object,
  maxWidth: PropTypes.string
};

DialogAnimate.defaultProps = {
  maxWidth: 'xs',
};

export default function DialogAnimate({ open = false, onClose, children, maxWidth, ...other }) {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullWidth
          maxWidth={maxWidth}
          open={open}
          TransitionComponent={Transition}
          onClose={onClose}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
