import { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
// @mui
import { alpha } from '@mui/material/styles';
import { Tooltip, Box, Divider, Typography, Stack, MenuItem, Avatar } from '@mui/material';
// components
import {Link} from 'react-router-dom'
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from "../../../components/Iconify";
import useSettings from "../../../hooks/useSettings";
import useLocales from "../../../hooks/useLocales";
import useConfig from "../../../hooks/useConfig";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'home',
    linkTo: '/',
  },
  {
    label: 'profile',
    linkTo: '/users/profile/',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
    const { themeColorPresets } = useSettings();
    const { keycloak } = useKeycloak();
    const { authUser } = useConfig();
    const { translate } = useLocales();

    const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return keycloak.authenticated ? (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar>
          <Iconify color={themeColorPresets} icon={'bxs:user'} width={24} height={24} />
        </Avatar>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
              {`${authUser.lastName} ${authUser.firstName}`}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
                component={Link}
                key={option.label}
                to={option.label === 'profile'? `${option.linkTo}${authUser.username}` : option.linkTo}
                onClick={handleClose}
            >
              {translate(option.label)}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={() => keycloak.logout()}>{translate('general.logout')}</MenuItem>
      </MenuPopover>
    </>
  ) : (
      <Tooltip title={translate('general.login')}>
          <Avatar>
              <IconButtonAnimate onClick={() => keycloak.login()}>
                  <Iconify icon={'ph:sign-in'} width={20} height={20} />
              </IconButtonAnimate>
          </Avatar>
      </Tooltip>
  );
}
