import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web'

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};

RoleBasedGuard.defaultProps = {
  accessibleRoles: [],
}

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const {keycloak} = useKeycloak();
  if (!keycloak.authenticated) {
      keycloak.login();
      return;
  }

  const role = accessibleRoles.find(role => keycloak.hasRealmRole(role));
  if (accessibleRoles.length > 0 && !role) {
    return (
        <Container>
          <Alert severity="error">
            <AlertTitle>Permission Denied</AlertTitle>
            You do not have permission to access this page
          </Alert>
        </Container>
    );
  }

  return <>{children}</>;
}
