import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import {Button} from '@mui/material';
import useLocales from "../hooks/useLocales";

SnackbarAction.propTypes = {
    key: PropTypes.string,
};

export default function SnackbarAction ({key}) {
    const { closeSnackbar } = useSnackbar();
    const { translate } = useLocales();
    return (
        <>
            <Button variant="contained" size="small" color='info' onClick={() => { closeSnackbar(key) }}>
                {translate('general.dismiss')}
            </Button>
        </>
    )
}