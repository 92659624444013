import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import {useKeycloak} from "@react-keycloak/web";
import { NavListRoot } from './NavList';
import useLocales from "../../../hooks/useLocales";

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
    const { translate } = useLocales();
    const {keycloak} = useKeycloak();
    const isRenderGroup = (group) => {
        const home = group.items.find(item => item.title === 'home');
        if (home) {
            return true;
        }

        if (!keycloak.authenticated) {
            return false
        }
        const accessibleItem = group.items.filter(item => {
            if (item.roles?.length > 0) {
                const role = item.roles?.find(role => keycloak.hasRealmRole(role));
                return !!role;
            }
            return true;
        });
        return accessibleItem.length > 0;
    }

    return (
    <Box {...other}>
      {navConfig.map((group) => isRenderGroup(group) && (
              <List key={group.subheader || 'home'} disablePadding sx={{ px: 2 }}>
                  <ListSubheaderStyle
                      sx={{
                          ...(isCollapse && {
                              opacity: 0,
                          }),
                      }}
                  >
                      {translate(group.subheader)}
                  </ListSubheaderStyle>

                  {group.items.map((list) => {
                      if (list.title === 'home') {
                          return <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />;
                      }

                      let isRender = true;
                      if (!keycloak.authenticated) {
                          isRender = false;
                      } else {
                          const roleIndex = list.roles?.findIndex(role => keycloak.hasRealmRole(role));
                          isRender = roleIndex !== -1;
                      }
                      return isRender && (
                          <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                      )
                  })}
              </List>
          ))}
    </Box>
  );
}
