// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import { QueryClientProvider, QueryClient } from 'react-query';
import { SnackbarProvider } from 'notistack';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// contexts
import {SettingsProvider} from './contexts/SettingsContext';
import {CollapseDrawerProvider} from './contexts/CollapseDrawerContext';
//
import App from './App';
import keycloak, {keycloakInitOptions} from "./utils/keycloak";
import LoadingScreen from "./components/LoadingScreen";
import SnackbarAction from "./components/SnackbarAction";
import {BackEndConfigProvider} from "./providers/BackEndConfigProvider";
import IdleProvider from "./providers/IdelTimerProvider";

// ----------------------------------------------------------------------
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            cacheTime: 0,
            retry: 1,
        },
    },
})


ReactDOM.render(
    <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakInitOptions} LoadingComponent={<LoadingScreen/>}>
        <HelmetProvider>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        action={SnackbarAction}
                    >
                        <IdleProvider>
                            <BackEndConfigProvider>
                                <SettingsProvider>
                                    <CollapseDrawerProvider>
                                        <BrowserRouter>
                                            <App/>
                                        </BrowserRouter>
                                    </CollapseDrawerProvider>
                                </SettingsProvider>
                            </BackEndConfigProvider>
                        </IdleProvider>
                    </SnackbarProvider>
                </LocalizationProvider>
            </QueryClientProvider>
        </HelmetProvider>
    </ReactKeycloakProvider>,
    document.getElementById('root')
);
