import Keycloak from 'keycloak-js';
import settings from '../settings';

const keycloak = new Keycloak({
    url: settings.keycloak.url,
    realm: settings.keycloak.realm,
    clientId: settings.keycloak.clientId,
});

export default keycloak;

export const keycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
};