import React from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {BackEndConfigContext} from "../providers/BackEndConfigProvider";
import settings from "../settings";

function useConfig() {
    const context = React.useContext(BackEndConfigContext);
    const { keycloak } = useKeycloak();
    if (!context) {
        throw new Error(`useConfig must be used within an BackEndConfigProvider`);
    }
    const isOwner = keycloak.realmAccess?.roles.includes(settings.realmRoles.owner);
    const isFinanceManager = keycloak.realmAccess?.roles.includes(settings.realmRoles.financeManager);
    const isManageFinanceReport = keycloak.realmAccess?.roles.includes(settings.realmRoles.manageFinanceReport);
    const isBranchManager = keycloak.realmAccess?.roles.includes(settings.realmRoles.branchManager);
    const isAssistantBranchManager = keycloak.realmAccess?.roles.includes(settings.realmRoles.assistantBrandManager);
    const isReceptionist = keycloak.realmAccess?.roles.includes(settings.realmRoles.adminReceptionist);
    const isManageLanguage = keycloak.realmAccess?.roles.includes(settings.realmRoles.manageLanguage);
    const isBranchManagerOrReceptionist = isBranchManager || isReceptionist;
    let belongedBranchId = '';
    if (context.authUser.id) {
        if (isReceptionist || isAssistantBranchManager) {
            belongedBranchId = context.authUser.schoolBranchId;
        } else if (isBranchManager) {
            belongedBranchId = context.authUser.manageSchoolBranchId;
        }
    }
    let cps = [];
    if (context.provinces) {
        const { provinces } = context;
        cps = provinces;
    }

    return {
        ...context,
        isOwner,
        isManageFinanceReport,
        isFinanceManager,
        isBranchManager,
        isReceptionist,
        isBranchManagerOrReceptionist,
        isAssistantBranchManager,
        isManageLanguage,
        belongedBranchId,
        provinces: cps,
    };
}

export default useConfig;
